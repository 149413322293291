import axios from "axios";
import * as conf from "../conf.yml";
import restAdapter from "@/restAdapter";

const AUTH_KEY = "app-user-token";

declare const window: any;

/**
 * This module handle the auth user
 */
/* eslint-disable @typescript-eslint/camelcase */
export default {
  TOKEN_KEY: AUTH_KEY,
  authenticated: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    expiresAt: null,
    rememberToken: null,
    isRememberToken: false,
    role: {},
    account: {},
    id: null,
    avatar: null,
  },
  can: {}, // user permissions

  login(credentials: any) {
    const url = conf.default.server.host + "/api/login";
    const uninterceptedAxiosInstance = axios.create();

    return uninterceptedAxiosInstance
      .post(url, credentials)
      .then((response) => {
        if (response.data && response.data.success) {
          this.setAuth(response.data.success);
        }
        return response;
      });
  },
  extractSocialDetails(provider: string, payload: any) {
    const url = conf.default.server.host + "/api/social/" + provider + "/extract_details";
    const uninterceptedAxiosInstance = axios.create();

    return uninterceptedAxiosInstance
      .post(url, payload)
      .then((response) => {
        if(response.data.already_registered){
          window.localStorage.removeItem("provider");
          window.localStorage.removeItem("socialLoginResponse");
          this.setAuth(response.data.already_registered);
        } else {
          window.localStorage.setItem('provider', JSON.stringify(provider));
          window.localStorage.setItem('socialLoginResponse', JSON.stringify(payload));
        }
        return response;
      });
  },
  logout() {
    const url = conf.default.server.host + "/api/logout";
    const options = {
      headers: { Authorization: this.getAuthToken() },
    };

    return axios.post(url, {}, options).then((response) => {
      this.updateAuth({
        firstName: "",
        lastName: "",
      });
      window.localStorage.removeItem(AUTH_KEY);
      window.Echo.leave("App.Models.User." + this.user.id);
      return response;
    }).catch(() => {
      this.updateAuth({
        firstName: "",
        lastName: "",
      });
      window.localStorage.removeItem(AUTH_KEY);
      window.Echo.leave("App.Models.User." + this.user.id);
    });
  },
  setAuth(data: any): void {
    this.user.firstName = data.first_name;
    this.user.lastName = data.last_name;
    this.user.email = data.email;
    this.user.token = data.token;
    this.user.rememberToken = data.rememberToken;
    this.user.expiresAt = data.expires_at;
    this.user.role = data.role;
    // TODO: backend change
    this.user.account = data.account;
    this.user.id = data.id;
    this.user.isRememberToken = data.isRememberToken;
    this.user.avatar = data.avatar;


    this.can = {}; // permissions

    window.localStorage.setItem(AUTH_KEY, JSON.stringify(this.user));

    window.Echo.connector.pusher.config.auth.headers["Authorization"] =
      "Bearer " + data.token;
  },
  updateAuth(data: any, updateLocalstorage = false): void {
    if (!data) {
      return;
    }

    this.user.firstName = data.firstName || this.user.firstName;
    this.user.lastName = data.lastName || this.user.lastName;
    this.user.email = data.email || this.user.email;
    this.user.token = data.token || this.user.token;
    this.user.rememberToken = data.rememberToken || this.user.rememberToken;
    this.user.expiresAt = data.expiresAt || this.user.expiresAt ;
    this.user.role = data.role || this.user.role;
    this.user.account = data.account || this.user.account;
    this.user.id = data.id || this.user.id;
    this.user.isRememberToken = data.isRememberToken || this.user.isRememberToken;
    this.user.avatar = data.avatar || this.user.avatar;

    if (updateLocalstorage === true) {
      window.localStorage.setItem(AUTH_KEY, JSON.stringify(this.user));
    }
  },
  updateAuthRoles(data: any): void {
    const authJson = window.localStorage.getItem(AUTH_KEY) as any;
    const authData = JSON.parse(authJson);

    authData.isCompany = data.isCompany;
    authData.subRoles = data.subRoles;

    this.updateAuth(authData);
    window.localStorage.setItem(AUTH_KEY, JSON.stringify(this.user));
  },
  checkAuth(): boolean {
    const authJson = window.localStorage.getItem(AUTH_KEY) as any;
    const authData = JSON.parse(authJson);
    this.updateAuth(authData);
    if (authData && authData.token) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  },
  checkUserTypeAssigned(): boolean {
    const authJson = window.localStorage.getItem(AUTH_KEY) as any;
    const authData = JSON.parse(authJson);
    this.updateAuth(authData);
    if (authData && authData.userType) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  },
  getAuthToken(): string {
    return "Bearer " + this.user.token;
  },
  getRememberToken(): string {
    return "Bearer " + this.user.rememberToken;
  },
};
