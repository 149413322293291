<template>
  <span>
    <ellipse-icon
      :width="'59px'"
      :height="'59px'"
      border="2px"
      :variant="variant"
      :fill="background"
      v-slot="props"
    >
      <design-icon v-if="traceName == 'DesignIcon'" :fill="props.fill" />
      <raw-material-icon
        v-else-if="traceName == 'RawMaterialIcon'"
        :fill="props.fill"
      />
      <spinning-icon
        v-else-if="traceName == 'SpinningIcon'"
        :fill="props.fill"
      />
      <weaving-icon v-else-if="traceName == 'WeavingIcon'" :fill="props.fill" />
      <dyeing-icon v-else-if="traceName == 'DyeingIcon'" :fill="props.fill" />
      <sampling-icon
        v-else-if="traceName == 'SamplingIcon'"
        :fill="props.fill"
      />
      <trims-icon v-else-if="traceName == 'TrimsIcon'" :fill="props.fill" />
      <manufacture-icon
        v-else-if="traceName == 'ManufacturingIcon'"
        :fill="props.fill"
      />
      <delivery-icon
        v-else-if="traceName == 'DeliveryIcon'"
        :fill="props.fill"
      />
      <repairs-icon v-else-if="traceName == 'RepairsIcon'" :fill="props.fill" />
      <resales-icon v-else-if="traceName == 'ResalesIcon'" :fill="props.fill" />
      <recycles-icon
        v-else-if="traceName == 'RecyclesIcon'"
        :fill="props.fill"
      />
    </ellipse-icon>
  </span>
</template>

<script>
export default {
  name: "TraceServicesIcon",
  props: {
    traceName: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "primary",
    },
    background: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped></style>
