<template>
  <v-app>
    <div>
      <popup-window
        :active="active"
        title="Request Account"
        :has-close="true"
        :has-back="false"
        noMargin
        @popup-close="close"
      >
        <div class="form-container">
          <v-form ref="accountRequestForm">
            <v-text-field
              v-model="model.name"
              placeholder="Name"
              outlined
              clearable
              dense
              :disabled="saving"
              :rules="[rules.requiredName, rules.maxLengthName]"
            />
            <v-text-field
              v-model="model.email"
              placeholder="Email"
              outlined
              :disabled="saving"
              clearable
              dense
              :rules="[rules.requiredEmail, rules.email, rules.maxLengthEmail]"
            />
            <v-text-field
              v-model="model.organizationName"
              placeholder="Business name"
              outlined
              :disabled="saving"
              clearable
              dense
              :rules="[rules.requiredCompanyName, rules.maxLengthCompanyName]"
            />
            <v-textarea
              v-model="model.message"
              outlined
              placeholder="Message"
              :disabled="saving"
              dense
              :rules="[rules.maxLengthMessage]"
            />
            <div class="btn-container">
              <ui-button
                class="submit-btn primary long"
                @click="submit"
                title="Submit"
              />
            </div>
          </v-form>
        </div>
      </popup-window>
    </div>
  </v-app>
</template>

<script>
/* eslint-disable */
import { email } from "vuelidate/lib/validators";
import validation from "../../validation";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import PopupWindow from "../base/PopupWindow.vue";

export default {
  name: "OrganizationRequest",
  components: { PopupWindow },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    model: {
      name: null,
      email: null,
      organizationName: null,
      message: null,
    },
    saving: false,
    rules: {
      requiredName: (value) => !!value || "Name is required",
      requiredEmail: (value) => !!value || "Email is required",
      requiredCompanyName: (value) => !!value || "Business name is required",
      email: (value) => {
        if (!value) {
          return "Email is required";
        }
        const validEmail = email(validation.emailFormatter(value));
        return validEmail || "Invalid email";
      },
      maxLengthName: (value) =>
        (!value || value.length) <= 255 ||
        "Name must be less than 255 characters",
      maxLengthEmail: (value) =>
        (!value || value.length) <= 255 ||
        "Email must be less than 255 characters",
      maxLengthCompanyName: (value) =>
        (!value || value.length) <= 255 ||
        "Business name must be less than 255 characters",
      maxLengthMessage: (value) =>
        (!value || value.length) <= 1000 ||
        "Message must be less than 1000 characters",
    },
  }),

  methods: {
    submit() {
      if (!this.$refs.accountRequestForm.validate()) {
        return;
      }

      this.saving = true;
      const data = {
        requester_name: this.model.name,
        requester_email: this.model.email,
        organization_name: this.model.organizationName,
        message: this.model.message,
      };

      restAdapter
        .post("/api/organization_requests", data)
        .then((response) => {
          notification.success("Organization request successfully sent.");
          this.saving = false;
          this.clearForm();
          this.$emit("close");
        })
        .catch((error) => {
          notification.error(error);
          this.saving = false;
        });
    },
    clearForm() {
      this.model.name = null;
      this.model.email = null;
      this.model.organizationName = null;
      this.model.message = null;
      this.$refs.accountRequestForm.resetValidation();
    },
    close() {
      this.clearForm();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  all: unset;
}
.theme--light.v-application {
  all: unset;
}
.v-application {
  all: unset;
}
.v-application::v-deep .v-application--wrap {
  all: unset;
}
a {
  color: #442d65 !important;
}

.btn-container::v-deep .md-button .md-ripple {
  padding: 0 0px !important;
}

.v-textarea::v-deep .v-textarea__slot {
  position: relative;
}

.v-textarea::v-deep .v-icon {
  position: absolute;
  margin-right: 30px;
  color: #442d65;
}

.form-container {
  padding: 10px 8% 10px 8%;
}

.btn-container {
  display: flex;
  justify-content: right;
  margin-right: -10px;
}
</style>
