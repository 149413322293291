import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify.js";
import * as conf from "./conf.yml";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSocialauth from "vue-social-auth";
import Echo from "laravel-echo";
import auth from "./auth";

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "leaflet/dist/leaflet.css";
Vue.use(VueMaterial);

import Notifications from "vue-notification";
Vue.use(Notifications);

//import VueMoment from 'vue-moment';
//Vue.use(VueMoment);
//Vue.use(require('vue-moment'));

import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.use(VueMoment, {
  moment,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: conf.default.server.googleClientId,
      redirectUri: conf.default.server.redirectUri,
    },
  },
});

//New Template Icons
import BaseIcon from "./views/dashboard/components/core/BaseIcon.vue";
import EllipseIcon from "./views/dashboard/components/core/EllipseIcon.vue";
import ShippingTruck from "./assets/icons/ShippingTruck.vue";
import ProjectBoard from "./assets/icons/ProjectBoard.vue";
import BomIcon from "./assets/icons/BomIcon.vue";
import CostIcon from "./assets/icons/CostIcon.vue";
import ClothingServices from "./assets/icons/ClothingServices.vue";
import OptionsIcon from "./assets/icons/OptionsIcon.vue";
import TraceIcon from "./assets/icons/TraceIcon.vue";
import VerifyIcon from "./assets/icons/VerifyIcon.vue";
import AlarmIcon from "./assets/icons/AlarmIcon.vue";
import StatusIcon from "./assets/icons/StatusIcon.vue";
import AccountIcon from "./assets/icons/AccountIcon.vue";
import CompanyIcon from "./assets/icons/CompanyIcon.vue";
import TechPackIcon from "./assets/icons/TechPackIcon.vue";
import FabricIcon from "./assets/icons/FabricIcon.vue";
import UpdateStatus from "./assets/icons/UpdateStatus.vue";
import EditInquiry from "./assets/icons/EditInquiry.vue";
import AssignIcon from "./assets/icons/AssignIcon.vue";
import DuplicateIcon from "./assets/icons/DuplicateIcon.vue";
import DeleteIcon from "./assets/icons/DeleteIcon.vue";
import DashboardIcon from "./assets/icons/DashboardIcon.vue";
import NotificationBell from "./assets/icons/NotificationBell.vue";
import Warning from "./assets/notification-icons/Warning.vue";
import Danger from "./assets/notification-icons/Danger.vue";
import Success from "./assets/notification-icons/Success.vue";
import PasswordLockIcon from "./assets/icons/PasswordLockIcon.vue";
import StyleFile from "./assets/icons/StyleFile.vue";
import ProfileSettingsIcon from "./assets/icons/ProfileSettingsIcon.vue";
import ActivityLogIcon from "./assets/icons/ActivityLogIcon.vue";
import FeedbackIcon from "./assets/icons/FeedbackIcon.vue";
import LogoutIcon from "./assets/icons/LogoutIcon.vue";
import CreateStyleIcon from "./assets/icons/CreateStyleIcon.vue";
import CreateCollectionIcon from "./assets/icons/CreateCollectionIcon.vue";

Vue.component("base-icon", BaseIcon);
Vue.component("ellipse-icon", EllipseIcon);
Vue.component("shipping-truck", ShippingTruck);
Vue.component("project-board", ProjectBoard);
Vue.component("bom-icon", BomIcon);
Vue.component("cost-icon", CostIcon);
Vue.component("clothing-services", ClothingServices);
Vue.component("options-icon", OptionsIcon);
Vue.component("trace-icon", TraceIcon);
Vue.component("verify-icon", VerifyIcon);
Vue.component("alarm-icon", AlarmIcon);
Vue.component("status-icon", StatusIcon);
Vue.component("account-icon", AccountIcon);
Vue.component("company-icon", CompanyIcon);
Vue.component("tech-pack-icon", TechPackIcon);
Vue.component("fabric-icon", FabricIcon);
Vue.component("update-status", UpdateStatus);
Vue.component("edit-inquiry", EditInquiry);
Vue.component("assign-icon", AssignIcon);
Vue.component("duplicate-icon", DuplicateIcon);
Vue.component("delete-icon", DeleteIcon);
Vue.component("dashboard-icon", DashboardIcon);
Vue.component("notification-bell", NotificationBell);
Vue.component("warning", Warning);
Vue.component("danger", Danger);
Vue.component("success", Success);
Vue.component("password-lock", PasswordLockIcon);
Vue.component("style-list", StyleFile);
Vue.component("profile-settings-icon", ProfileSettingsIcon);
Vue.component("activity-log", ActivityLogIcon);
Vue.component("feedback-icon", FeedbackIcon);
Vue.component("logout-icon", LogoutIcon);
Vue.component("create-style-icon", CreateStyleIcon);
Vue.component("create-collection-icon", CreateCollectionIcon);

//New Custom Buttons
import UiButton from "./components/base/UiButton.vue";
import ButtonCard from "./components/base/ButtonCard.vue";
import OrganizationRequest from "./components/organization/OrganizationRequest.vue";

Vue.component("ui-button", UiButton);
Vue.component("button-card", ButtonCard);
Vue.component("organization-request", OrganizationRequest);

//trace customer view icons
import TraceServicesIcon from "./views/dashboard/components/core/TraceServicesIcon.vue";
import DeliveryIcon from "./assets/trace-icon-customer-view/DeliveryIcon.vue";
import DesignIcon from "./assets/trace-icon-customer-view/DesignIcon.vue";
import DyeingIcon from "./assets/trace-icon-customer-view/DyeingIcon.vue";
import ManufacturingIcon from "./assets/trace-icon-customer-view/ManufacturingIcon.vue";
import RawMaterialIcon from "./assets/trace-icon-customer-view/RawMaterialIcon.vue";
import SamplingIcon from "./assets/trace-icon-customer-view/SamplingIcon.vue";
import SpinningIcon from "./assets/trace-icon-customer-view/SpinningIcon.vue";
import TrimsIcon from "./assets/trace-icon-customer-view/TrimsIcon.vue";
import WeavingIcon from "./assets/trace-icon-customer-view/WeavingIcon.vue";
import CareInstructionIcon from "./assets/trace-icon-customer-view/CareInstructionIcon.vue";
import BomItemIcon from "./assets/trace-icon-customer-view/BomItemIcon.vue";
import RecycleIcon from "./assets/trace-icon-customer-view/RecycleIcon.vue";
import RepairIcon from "./assets/trace-icon-customer-view/RepairIcon.vue";
import ResaleIcon from "./assets/trace-icon-customer-view/ResaleIcon.vue";
import RepairRecycleResaleIcon from "./assets/trace-icon-customer-view/RepairRecycleResaleIcon.vue";
import FabricCompositionIcon from "./assets/trace-icon-customer-view/FabricCompositionIcon.vue";
import SustainabilityIcon from "./assets/trace-icon-customer-view/SustainabilityIcon.vue";
import SupplyChainIcon from "./assets/trace-icon-customer-view/SupplyChainIcon.vue";
import CustomerFeedbackIcon from "./assets/trace-icon-customer-view/CustomerFeedbackIcon.vue";
import GeoTaggingIcon from "./assets/trace-icon-customer-view/GeoTaggingIcon.vue";
import LocationIcon from "./assets/icons/LocationIcon.vue";
import CertificationsIcon from "./assets/icons/CertificationsIcon.vue";

Vue.component("trace-services-icon", TraceServicesIcon);
Vue.component("delivery-icon", DeliveryIcon);
Vue.component("design-icon", DesignIcon);
Vue.component("dyeing-icon", DyeingIcon);
Vue.component("manufacture-icon", ManufacturingIcon);
Vue.component("raw-material-icon", RawMaterialIcon);
Vue.component("sampling-icon", SamplingIcon);
Vue.component("spinning-icon", SpinningIcon);
Vue.component("trims-icon", TrimsIcon);
Vue.component("weaving-icon", WeavingIcon);
Vue.component("care-instruction-icon", CareInstructionIcon);
Vue.component("bom-item-icon", BomItemIcon);
Vue.component("fabric-composition-icon", FabricCompositionIcon);
Vue.component("sustainability-icon", SustainabilityIcon);
Vue.component("supply-chain-icon", SupplyChainIcon);
Vue.component("customer-feedback-icon", CustomerFeedbackIcon);
Vue.component("geo-tagging-icon", GeoTaggingIcon);
Vue.component("location-icon", LocationIcon);
Vue.component("certifications-icon", CertificationsIcon);
Vue.component("repairs-icon", RepairIcon);
Vue.component("resales-icon", ResaleIcon);
Vue.component("recycles-icon", RecycleIcon);
Vue.component("repair-recycle-resale-icon", RepairRecycleResaleIcon);

// Bom generic icons
import BomItemGenericIcon from "./views/dashboard/components/core/BomItemGenericIcon.vue";
import Body from "./assets/bom/Body.vue";
import Button from "./assets/bom/Button.vue";
import CareLabel from "./assets/bom/CareLabel.vue";
import Carton from "./assets/bom/Carton.vue";
import Drawcord from "./assets/bom/Drawcord.vue";
import HangTagThread from "./assets/bom/HangTagThread.vue";
import HangTag from "./assets/bom/HangTag.vue";
import Lining from "./assets/bom/Lining.vue";
import MainLabel from "./assets/bom/MainLabel.vue";
import Other from "./assets/bom/Other.vue";
import Packaging from "./assets/bom/Packaging.vue";
import Thread from "./assets/bom/Thread.vue";
import Zipper from "./assets/bom/Zipper.vue";

Vue.component("bom-item-generic-icon", BomItemGenericIcon);
Vue.component("body-icon", Body);
Vue.component("button-icon", Button);
Vue.component("care-label-icon", CareLabel);
Vue.component("carton-icon", Carton);
Vue.component("drawcord-icon", Drawcord);
Vue.component("hang-tag-thread-icon", HangTagThread);
Vue.component("hang-tag-icon", HangTag);
Vue.component("lining-icon", Lining);
Vue.component("main-label-icon", MainLabel);
Vue.component("other-icon", Other);
Vue.component("packaging-icon", Packaging);
Vue.component("thread-icon", Thread);
Vue.component("zipper-icon", Zipper);

declare const window: any;

window.Pusher = require("pusher-js");

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if (error.response.status === 401) {
    //   router.push({ name: "Not Found" });
    // } else if (error.response.status === 404) {
    //   router.push({ name: "Not Found" });
    // }
    return Promise.reject(error);
  }
);

//Popup Window
import PopupWindow from "./components/base/PopupWindow.vue";
Vue.component("popup-window", PopupWindow);

//Truncate with mouse hovering
import TruncateHovering from "./components/base/TruncateHovering.vue";
Vue.component("truncate-hovering", TruncateHovering);

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "local",
  wsHost: conf.default.server.domain,
  wsPort: 6001,
  wssPort: 6001,
  forceTLS: conf.default.server.forceTLS ? true : false,
  disableStats: true,
  enabledTransport: ["ws", "wss"],
  authEndpoint: conf.default.server.host + "/api/broadcasting/auth",
  auth: {
    headers: {
      Authorization: auth.getAuthToken(),
    },
  },
});

window.onbeforeunload = function (e) {
  if (store.state.routeLeaveAlert && conf.default.routeLeaveAlert) {
    return "Changes you made may not be saved.";
  } else {
    window.onbeforeunload = null;
  }
};

import userPermissions from "./validation/user-permission";

Vue.prototype.$userPermissions = userPermissions;
