<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3302 19.1098L21.511 21.5265L29.196 8.25898L34.6335 17.6598L28.3502 21.2848L41.5452 24.8131L45.0977 11.6181L38.8144 15.2431L33.3769 5.81815C33.0614 5.26716 32.64 4.78409 32.1369 4.3968C31.6338 4.00951 31.059 3.72568 30.4456 3.56167C29.8323 3.39766 29.1925 3.35672 28.5633 3.44122C27.934 3.52572 27.3277 3.73397 26.7794 4.05398C26.1269 4.41648 25.571 4.94815 25.1602 5.57648M24.1694 48.3273V43.494H8.84771L14.261 34.069L20.5444 37.694L17.016 24.499L3.79688 28.0273L10.0802 31.6523L4.64271 41.0773C4.2303 41.7928 4.00777 42.6018 3.9963 43.4275C3.98483 44.2532 4.18479 45.0681 4.57716 45.7948C4.96954 46.5214 5.54128 47.1355 6.23801 47.5788C6.93474 48.0221 7.73329 48.2798 8.55771 48.3273M46.0644 27.7856L41.8594 30.2023L49.5444 43.494H38.6694V36.244L29.0027 45.9106L38.6694 55.5773V48.3273H49.5444C52.2269 48.3273 54.3777 46.1765 54.3777 43.494C54.3777 42.7448 54.1844 41.9957 53.846 41.3432L46.0644 27.7856Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "RecycleIcon",
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    fill: {
      type: String,
      default: "#70D0CE",
    },
    viewBox: {
      type: [Number, String],
      default: "11 14 35 30",
    },
  },
};
</script>
